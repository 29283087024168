// 
// pagination.scss
//


// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 6px;
        border: none;
        background-color: gray;
        
    }
    .page-item {
        &:last-child {
            .page-link {
                margin-right: 0px;
                background-color: transparent;
            }
        }
        &:first-child {
            .page-link {
                margin-left: 0px;
                background-color: transparent;
            }
        }

    }
    .page-item.active {
        .page-link {
            background-color: #431f48;
        }
    }


}

.pagination {
    a {
        line-height: $line-height-sm !important;
    }
}