// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

svg {
  max-width: 72px;
  max-height: 72px;
}

.rbt-input-main {
  color: whitesmoke!important;
}