//
// forms.scss
//

#change-password {
    label {
        color: gray;
    }
    input {
        margin-bottom: 16px;
        border-radius: 8px;
    }
    .actions {
        button {
            margin: 16px;
            border-radius: 8px;
        }
        .primary {
            background-color: #2dc0c0;
            border-color: #2dc0c0;
            border-radius: 8px;
        }
    }
}

.form-control,
.form-select {
    box-shadow: $components-shadow-sm;
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: #{map-get($grays, '300')};
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: #{map-get($grays, '100')};
        padding: 10px;
        border: #{$border-width} solid #{map-get($grays, '300')};
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box
.search-bar {
    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

.search-box {
    position: relative;
    .form-control {
        padding-right: 36px;
    }
    .search-icon {
        position: absolute;
        top: 7px;
        right: 12px;
        font-size: 16px;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather !important;
        content: '\e86a';
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: '\e86e';
        }
    }
}

// dark mode
body[data-layout-color='dark'] {
    .comment-area-box {
        .form-control {
            border-color: #{map-get($dark-grays, '300')};
        }
        .comment-area-btn {
            background-color: #{map-get($dark-grays, '100')};
            border: #{$border-width} solid #{map-get($dark-grays, '300')};
        }
    }
}


#switch-override-color {
    display: flex;
    &::before {
        content: 'Não';
        position: relative;
        margin-right: 8px;
    }
    &::after {
        content: 'Sim';
        position: relative;
        margin-left: 8px;
    }
}